/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    /**
     * Universal click or tap event
     * (replacement for 'click')
     */

    var clickEvent = function () {
        return ('undefined' !== typeof window.touchstart ? 'touchstart' : 'click');
    };

    var winWidth = $(window).width(),
        xs       = false,
        sm       = false,
        md       = false;

    /**
    * Mobile
    * Window width
    */

    function mobileCheck() {
        if ( winWidth >= '992' ) {
            lg = true;
            md = false;
            sm = false;
            xs = false;
        } else if ( winWidth >= '768' ) {
            lg = false;
            md = true;
            sm = false;
            xs = false;
        } else if ( winWidth >= '576' ) {
            lg = false;
            md = false;
            sm = true;
            xs = false;
        } else {
            lg = false;
            md = false;
            sm = false;
            xs = true;
        }
    }

    mobileCheck();

    $(window).resize(function() {
        winWidth = $(window).width();
        mobileCheck();
    });

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Delta = {

        // All pages
        'common': {

            init: function() {
                // JavaScript to be fired on all pages

                /**
                 * Site header
                 */

                var siteHeaderStyle = function() {
                    if ( 10 <= $(document).scrollTop() ) {
                        $('#site-header').addClass('is-not-top');
                    } else {
                        $('#site-header').removeClass('is-not-top');
                    }
                };

                siteHeaderStyle();

                $(window).on('scroll', function() {
                    siteHeaderStyle();
                });

                /**
                 * Site nav
                 */

                var closePrimaryNavSubMenus = function($this) {
                    if ( ! $this ) {
                        $('#menu-primary .sub-menu-active').removeClass('sub-menu-active');
                        $('#menu-primary .sub-menu').slideUp(150);
                    } else {
                        $('#menu-primary .sub-menu-active').not($this).removeClass('sub-menu-active');
                        $('#menu-primary .sub-menu').not($this.children('.sub-menu')).slideUp(150);
                    }
                },

                openPrimaryNavSubMenus = function($this) {
                    $this.addClass('sub-menu-active');
                    $this.children('.sub-menu').slideDown(250);
                },

                closeMobilePrimaryNav = function() {
                    closePrimaryNavSubMenus();
                    $('body').removeClass('mobile-nav-is-active');
                    $('.mobile-nav-trigger')
                        .removeClass('active').blur();
                    $('.mobile-nav-trigger').children('i')
                        .removeClass('la-close')
                        .addClass('la-navicon');
                    $('#site-nav .menu-primary-container').slideUp(150);
                },

                openMobilePrimaryNav = function() {
                    $('body').addClass('mobile-nav-is-active');
                    $('.mobile-nav-trigger')
                        .addClass('active').blur();
                    $('.mobile-nav-trigger').children('i')
                        .removeClass('la-navicon')
                        .addClass('la-close');
                    $('#site-nav .menu-primary-container').slideDown(250);
                };

                /* Not mobile */

                if ( lg ) {

                    $(document).on({
                        mouseenter: function(e) {
                            e.stopPropagation();
                            closePrimaryNavSubMenus();
                            openPrimaryNavSubMenus($(this));
                        },
                        mouseleave: function(e) {
                            e.stopPropagation();
                            closePrimaryNavSubMenus();
                        }
                    }, '#menu-primary > li.menu-item-has-children');

                }

                /* Mobile */

                // Open

                $(document).on(clickEvent(), '.mobile-nav-trigger', function(e) {
                    e.preventDefault();
                    if ( ! $('body').hasClass('mobile-nav-is-active') ) {
                        openMobilePrimaryNav();
                    }
                });

                // Close on body click

                $(document).on(clickEvent(), 'body.mobile-nav-is-active', function(e) {
                    if ( $(e.target) !== $('#menu-primary') &&
                        ! $(e.target).parents('#menu-primary').length ) {
                        closeMobilePrimaryNav();
                        closePrimaryNavSubMenus();
                    }
                });

                // Submenu expand

                if ( ! lg ) {

                    $(document).on(clickEvent(), '.menu-item-has-children .expander', function(e) {
                        e.preventDefault();
                        if ( ! $(this).parents('.menu-item').hasClass('sub-menu-active') ) {
                            closePrimaryNavSubMenus($(this).parents('.menu-item'));
                            openPrimaryNavSubMenus($(this).parents('.menu-item'));
                        } else {
                            closePrimaryNavSubMenus();
                        }
                    });

                }

                /* Window resize */

                $(window).resize(function() {

                    // if ( lg ) {
                    //     $('body').removeClass('mobile-nav-is-active');
                    //     $('#site-nav .menu-primary-container').show();
                    // } else {
                    //     $('#site-nav .menu-primary-container').hide();
                    //     closeMobilePrimaryNav();
                    // }

                });

                /* Hero */

                if ( $('.elementor-40').length && ! xs ) {
                    // Parallax
                    var heroParallax = function() {
                        var scrolled = $(window).scrollTop();
                        if ( scrolled > 0 ) {
                            $('.elementor-40 img').css('transform', 'translate(' + -(scrolled*0.25) + 'px, ' + -(scrolled*0.2) + 'px)');
                            $('.elementor-40 .elementor-element').css('background-position-y', (scrolled*0.2) + 'px');
                        }
                    };

                    $(window).scroll( function(e) {
                        heroParallax();
                    });
                }

                /* Modals */
                //
                // $('.modal').modal();
                //
                // $('.modal').on('show.bs.modal', function (e) {
                //   alert('FOO');
                // });

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }

        }, // common

        'home': {

            init: function() {
            }

        },

        'single': {

            init: function() {
            }

        },

        'passes': {

            init: function() {

              var passPurchaseContainerWp = new Waypoint({
                  element: document.getElementsByClassName('fm-pass-purchase-container'),
                  handler: function(direction) {
                      if ('down' === direction) {
                        $('.fm-pass-purchase-container').addClass('fm-sticky');
                      } else {
                        $('.fm-pass-purchase-container').removeClass('fm-sticky');
                      }
                  }
              });

            }

        },


    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Delta;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
